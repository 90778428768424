<template>
	<el-dialog :title="title" :before-close="close" :visible.sync="show"
		:width="$store.state.platform == 'pc'?'780px':'90vw'" top="20vh">
		<el-timeline>
			<el-timeline-item type="success">快递由【上海转运中心】发往【重庆转运中心】</el-timeline-item>
			<el-timeline-item>快递由【上海转运中心】发往【重庆转运中心】</el-timeline-item>
			<el-timeline-item>快递由【上海转运中心】发往【重庆转运中心】</el-timeline-item>
		</el-timeline>
	</el-dialog>
</template>

<script>
	export default {
		name: 'Logistic',
		data() {
			return {
				show: false,
				title: '运单号：SF88764927492761728',
				activities: [{
						content: '活动按期开始',
						timestamp: '2018-04-15'
					},
					{
						content: '通过审核',
						timestamp: '2018-04-13'
					},
					{
						content: '创建成功',
						timestamp: '2018-04-11'
					}
				]
			};
		},
		mounted() {
			this.show = true;
		},
		created() {},
		methods: {
			close() {
				this.$emit('close');
			}
		}
	};
</script>

<style lang="less" scoped>
	/deep/.el-dialog {
		border-radius: 8px;

		.el-dialog__body {
			padding: 0 40px;
		}

		.el-timeline {
			.el-timeline-item__tail {
				border-color: #D9D9D9;
			}

			.el-timeline-item {
				padding-bottom: 40px;

				.el-timeline-item__wrapper {
					.el-timeline-item__content {
						font-size: 14px;
						font-weight: 400;
						color: #000000;
					}
				}
			}

			.el-timeline-item__node {
				width: 10px;
				height: 10px;
				left: -3px;
				border: 3px solid #fff;
				background: radial-gradient(#D9D9D9 35%, #fff 35%, #fff 60%, #D9D9D9 60%);
			}

			.el-timeline-item__node--success {
				background: radial-gradient(#000000 35%, #fff 35%, #fff 60%, #000000 60%);
			}
		}
	}

	/deep/.el-dialog__header {
		padding: 40px 40px;

		.el-dialog__title {
			font-size: 18px;
			font-weight: bold;
			color: #000000;
			line-height: 25px;
		}

		.el-dialog__headerbtn {
			top: 35px;
			right: 40px;

			.el-dialog__close {
				font-size: 20px;
				font-weight: bold;
				color: #000;
			}
		}
	}

	@media screen and (max-width: 500px) {
		/deep/.el-dialog__header {
			padding: 3vw 3vw;

			.el-dialog__headerbtn {
				top: 3vw;
				right: 3vw;
			}
		}

		/deep/.el-dialog {
			border-radius: 8px;

			.el-dialog__body {
				padding: 3vw 3vw 0;
			}

			.el-timeline {
				.el-timeline-item__tail {
					border-color: #D9D9D9;
				}

				.el-timeline-item {
					padding-bottom: 6vw;
				}
			}
		}
	}
</style>
